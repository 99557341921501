<template>
  <section>
    <div class="headerjob">
      <v-container class="container-custom">
        <v-row>
          <v-col> <h1 class="titlepage">Add Your Company Details</h1></v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="container-custom padding-inside">
      <v-row>
        <v-col md="12">
          <AddCompany />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AddCompany from "@/components/AddCompany";
export default {
  name: "CompanyAdd",
  components: { AddCompany },
  data: () => ({
    headercontent: {
      title: "Add Company Detail",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
};
</script>

<style lang="scss" scoped></style>
